<template>
    <v-app id="apply_payment">

        <!-- Apply Payment header -->
        <v-card flat>
            <v-card-title>
                <v-flex text-xs-center>
                    <h3 class="text-center font-weight-bold header-space">
                        Apply Payment

                        <v-btn
                            class="page_close_btn"
                            icon
                            to="/calendar"
                        >
                            <v-icon color="black" large>mdi-close</v-icon>
                        </v-btn>

                    </h3>
                </v-flex>
            </v-card-title>
        </v-card>
        <!-- End header -->

        <v-divider></v-divider>

        <v-row no-gutters>
            <!-- Left block -->
            <v-col cols="12" sm="6" md="8">
                <v-sheet height="690" class="grey lighten-4" style="overflow-y: scroll;">

                    <!-- Invoice details -->
                    <div class="pa-10">
                        <v-card
                            max-width="470"
                            class="mx-auto pa-8"
                            elevation="3"
                            tile
                        >
                            <div class="text-center mt-3 mb-8">
                                <p class="text-h6 font-weight-regular mb-2">Invoice <span style="letter-spacing: 0px;">#{{ invoice_id }}</span></p>
                                <p class="text-body-2 blue-grey--text text--lighten-1">{{ moment(invoice_date).format('dddd, D MMM Y') }}</p>
                            </div>

                            <v-divider></v-divider>

                            <div class="py-4">
                                <template v-for="(item, i) in invoice_details">
                                    <div class="py-2" :key="'inv_det_'+i">
                                        <div class="text-body-2 blue-grey--text text--lighten-1">{{ item.qty }} item</div>
                                        <div class="d-flex flex-row flex-grow-1">
                                            <div class="d-flex flex-column flex-grow-1">
                                                <div class="text-body-1">{{ item.service }}</div>
                                                <div class="text-body-2 blue-grey--text text--lighten-1">
                                                    {{ moment(item.from_time, ["HH:mm:ss"]).format('h:mma') }}, 
                                                    {{ moment(item.date).format('D MMM Y') }} with {{ item.staff_name }}</div>
                                            </div>
                                            <div class="d-flex flex-column text-body-1 justify-center">
                                                {{ $currency }}{{ item.rate }}
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>

                            <v-divider></v-divider>

                            <div class="py-5 text-body-1">
                                <div class="d-flex flex-row flex-grow-1">
                                    <div class="d-flex flex-column flex-grow-1">
                                        Subtotal
                                    </div>
                                    <div class="d-flex flex-column">
                                        {{ $currency }}{{ invoice_sub_total }}
                                    </div>
                                </div>
                            </div>

                            <v-divider></v-divider>

                            <div class="py-5 text-body-1">
                                <div class="d-flex flex-row flex-grow-1">
                                    <div class="d-flex flex-column flex-grow-1">
                                        Total
                                    </div>
                                    <div class="d-flex flex-column">
                                        {{ $currency }}{{ invoice_total }}
                                    </div>
                                </div>
                            </div>

                            <v-divider v-if="payments.length > 0 || tips != ''"></v-divider>

                            <div v-if="tips != '' || payments.length > 0" class="py-5">
                                <div v-if="tips != ''">
                                    <div class="py-2 d-flex flex-row flex-grow-1">
                                        <div class="d-flex flex-column flex-grow-1">
                                            <div class="text-body-1">Tips</div>
                                            <!-- <div class="text-body-2 blue-grey--text text--lighten-1">Saturday, 26 Jun 2021 at 10:12pm</div> -->
                                        </div>
                                        <div class="d-flex flex-column text-body-1 justify-center">
                                            {{ $currency }}{{ tips }}
                                        </div>
                                    </div>
                                </div>

                                <div v-if="payments.length > 0">
                                    <div v-for="(item, i) in payments" :key="'pm_c'+i" class="py-2 d-flex flex-row flex-grow-1">
                                        <div class="d-flex flex-column flex-grow-1">
                                            <div class="text-body-1">{{ item.mode_of_payment }}</div>
                                            <!-- <div class="text-body-2 blue-grey--text text--lighten-1">Saturday, 26 Jun 2021 at 10:12pm</div> -->
                                        </div>
                                        <div class="d-flex flex-column text-body-1 justify-center">
                                            {{ $currency }}{{ item.amount }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <v-divider v-if="pay_types.length > 0"></v-divider>

                            <div class="mt-4 mb-4" v-if="pay_types.length > 0">
                                <template v-for="(pays, i) in pay_types">
                                    <div :key="'pays_type_div_'+i" class="d-flex flex-row justify-space-between">
                                        <p class="mb-0" :key="'pays_type_'+i">{{ pays.type }}</p>
                                        <p class="mb-0" :key="'pays_amnt_'+i" style="margin-right: -29px;">
                                            {{ $currency }}{{ pays.amount }}
                                            <span @click="delete_pay(i)" style="cursor:pointer;padding-left: 10px;padding-right: 30px;">
                                                <svg viewBox="0 0 18 18" width="12px" height="12px" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M17 1.914L16.086 1 9 8.086 1.914 1 1 1.914 8.086 9 1 16.086l.914.914L9 9.914 16.086 17l.914-.914L9.914 9z" fill="#101928"></path>
                                                </svg>
                                            </span>
                                        </p>
                                    </div>
                                </template>
                            </div>

                            <v-divider></v-divider>

                            <div class="py-5 text-body-1">
                                <div class="d-flex flex-row flex-grow-1">
                                    <div class="d-flex flex-column flex-grow-1">
                                        Balance
                                    </div>
                                    <div class="d-flex flex-column">
                                        {{ $currency }}{{ balance }}
                                    </div>
                                </div>
                            </div>

                            <div v-if="invoice_notes" class="py-1">
                                <div class="text-body-2 blue-grey--text text--lighten-1">
                                    {{ invoice_notes }}
                                </div>
                            </div>

                        </v-card>
                    </div>
                    <!-- End invoice details -->
                </v-sheet>
            </v-col>
            <!-- End left block -->

            <!-- Right block -->
            <v-col cols="6" md="4">
                <v-card flat class="pa-7 pb-5">
                    <div>
                        <v-avatar
                            color="grey lighten-2"
                            size="60"
                            class="mr-3"
                        >
                            <span style="width: 32px;height: 32px;">
                                <svg viewBox="0 0 20 22" xmlns="http://www.w3.org/2000/svg"><g fill="#FFF" stroke="#101928"><path d="M.5 21c0-5.5313 4.1043-9.5 9.5-9.5s9.5 3.9687 9.5 9.5v.5H.5V21z" stroke-linecap="round" stroke-linejoin="round"></path><path d="M15.5 6c0-3.0374-2.4626-5.5-5.5-5.5S4.5 2.9626 4.5 6s2.4626 5.5 5.5 5.5 5.5-2.4626 5.5-5.5z"></path></g></svg>
                            </span>
                        </v-avatar>

                        <span class="text-h6 font-weight-medium">
                            {{ customer }}
                        </span>
                    </div>
                </v-card>


                <v-divider></v-divider>

                 <v-card
                    color="white"
                    flat
                    height="422"
                    tile
                    v-if="paid_status == 'Paid'"
                > 
                    <v-container class="px-15 py-7 mt-16" fluid>
                        <v-layout align-center justify-center>
                            <div class="text-md-center">
                                <p class="custom_search_icon">
                                    <v-icon size="48px">$vuetify.icons.PaymentSvg</v-icon>
                                </p>
                                <p>Full payment has been added</p>
                                
                            </div>
                        </v-layout>
                    </v-container>
                    <v-container class="px-10">
                        <v-btn
                            class="express_checkout custom-transform-class font-weight-medium"
                            large
                            block
                            dark
                            color="#101928"
                            :to="'/invoice/'+invoice_id"
                        >
                            Save
                        </v-btn>
                        <div class="create_cli_btn full-underline text-center py-5">
                            <v-icon class="mr-1" color="#037aff">mdi-chevron-left</v-icon>
                            <span>Back to payments</span>
                        </div>
                    </v-container>
                </v-card>

                <div v-else style="position: relative">
                <!-- Pay buttons -->
                <v-card
                    color="white"
                    flat
                    height="500"
                    tile
                >
                    <div class="pt-6 pa-7">

                        <div>
                            <div class="subtitle-1 text-center font-weight-medium mb-1">Pay</div>
                            <v-text-field
                                v-model="pay_amount"
                                height="75"
                                solo
                                :prepend-inner-icon="$currency"
                                class="pay-big-icon"
                            ></v-text-field>
                        </div>

                        <v-row>
                            <v-col cols="6" :key="'pay_mode_col_'+i" v-for="(mode, i) in payment_modes">
                                <v-btn
                                    :key="'pay_mode_'+i"
                                    x-large
                                    color="#101928"
                                    dark
                                    height="65px"
                                    class="mr-2 text-capitalize"
                                    block
                                    @click="payment_type(mode.name)"
                                >
                                    {{ mode.name }}
                                </v-btn>
                            </v-col>

                            <v-col cols="6">
                                <v-btn
                                    x-large
                                    color="#101928"
                                    dark
                                    height="65px"
                                    class="text-capitalize pt-1"
                                    block
                                    @click="voucher_dialog = !voucher_dialog"
                                >
                                    Voucher
                                </v-btn>
                            </v-col>
                        </v-row>

                    </div>
                </v-card>
                <!-- End pay buttons -->

                <v-divider></v-divider>

                <!-- More option button -->
                <v-card flat>
                    <div class="ma-5 text-center">
                        <v-menu top offset-y rounded="0">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    large
                                    color="white"
                                    elevation="1"
                                    class="app_btn"
                                    block
                                >
                                    <span class="text-capitalize font-checkout">More options</span>
                                    <v-icon dense class="pl-3">mdi-chevron-down</v-icon>
                                </v-btn>
                            </template>
                            <v-list class="pa-0" dense>
                                <template v-for="(item, index) in more_options">
                                    <v-list-item
                                        :key="'more_opt_'+index"
                                        link
                                        :class="item.text_color"
                                        @click="more_option_click(item.value)"
                                    >
                                        <v-list-item-title class="text-center app_font" link>
                                            {{ item.label }}
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-divider
                                        v-if="index < more_options.length - 1"
                                        :key="'more_opt_div_'+item.value"
                                    ></v-divider>
                                </template>
                            </v-list>
                        </v-menu>
                    </div>
                </v-card>
                <!-- End more option button -->

                <!-- Payment drawer -->
                <v-navigation-drawer
                    v-model="payment_drawer"
                    absolute
                    temporary
                    right
                    width="490px"
                    class="bx-shadow-none"
                >
                    <v-card
                        color="white"
                        flat
                        height="422"
                        tile
                    > 
                        <v-container class="px-15 py-7 mt-16" fluid>
                            <v-layout align-center justify-center>
                                <div class="text-md-center">
                                    <p class="custom_search_icon">
                                        <v-icon size="48px">$vuetify.icons.PaymentSvg</v-icon>
                                    </p>
                                    <p>Full payment has been added</p>
                                    
                                </div>
                            </v-layout>
                        </v-container>
                        <v-container class="px-10">
                            <v-btn
                                class="express_checkout custom-transform-class font-weight-medium"
                                large
                                block
                                dark
                                color="#101928"
                                @click="CompleteSale()"
                            >
                                Complete Sale
                            </v-btn>
                            <div @click="back_to_payments()" class="create_cli_btn full-underline text-center py-5">
                                <v-icon class="mr-1" color="#037aff">mdi-chevron-left</v-icon>
                                <span>Back to payments</span>
                            </div>
                        </v-container>
                    </v-card>
                </v-navigation-drawer>
                <!-- End payment drawer -->
                </div>
            </v-col>
            <!-- End right block -->
        </v-row>

        <!-- Payment detail dialog -->
        <v-dialog
            v-model="payment_detail_dialog"
            width="448"
            persistent
            transition="dialog-bottom-transition"
            class="top-dialog"
        >
            <v-card
                class="mx-auto"
            >
                <v-card-title class="p-0 text-center">
                    <v-app-bar class="item_dialog_title" color="white" flat>
                        <div></div>
                        <v-app-bar-title class="text-h6 font-weight-bold">Payment Details</v-app-bar-title>
                        <v-btn
                            icon
                            @click="payment_detail_dialog = !payment_detail_dialog"
                        >
                            <v-icon large>mdi-close</v-icon>
                        </v-btn>
                    </v-app-bar>
                </v-card-title>

                <v-divider></v-divider>

                <v-card-text class="pa-6">
                    <v-row>
                        <v-col
                            cols="12"
                        >
                            <label class="dialog_label">Payment received by</label>

                            <v-row>
                                <v-col
                                    cols="12"
                                    class="pb-0"
                                >
                                    <v-select
                                        :items="staffs"
                                        item-text="employee_name"
                                        item-value="employee_name"
                                        solo
                                        v-model="invoice_staff"
                                    ></v-select>
                                </v-col>

                                <!-- <v-col
                                    cols="12"
                                    class="pt-0 pb-0"
                                >
                                    <label class="dialog_label">Invoice notes</label>
                                    <v-textarea
                                        counter="250"
                                        v-model="invoice_notes"
                                        auto-grow
                                        solo
                                        rows="1"
                                        >
                                    </v-textarea>
                                </v-col> -->
                                
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn
                        block
                        large
                        color="#101928"
                        dark
                        class="text-capitalize"
                        @click="payment_detail_dialog = !payment_detail_dialog"
                    >
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- End Payment detail dialog -->

        <!-- Redeem voucher dialog -->
        <v-dialog
            v-model="voucher_dialog"
            width="448"
            persistent
            transition="dialog-bottom-transition"
            class="top-dialog"
        >
            <v-card
                class="mx-auto"
            >
                <v-card-title class="p-0 text-center">
                    <v-app-bar class="item_dialog_title" color="white" flat>
                        <div></div>
                        <v-app-bar-title class="text-h6 font-weight-bold">Redeem Voucher</v-app-bar-title>
                        <v-btn
                            icon
                            @click="voucher_dialog = !voucher_dialog"
                        >
                            <v-icon large>mdi-close</v-icon>
                        </v-btn>
                    </v-app-bar>
                </v-card-title>

                <v-divider></v-divider>

                <div class="pa-6">
                    <v-row>
                        <v-col
                            cols="12"
                            class="pb-0"
                        >
                            <v-text-field
                                solo
                                placeholder="Enter Voucher Code"
                                prepend-inner-icon="mdi-magnify"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <div class="pa-9 d-flex flex-column justify-center align-center">
                                <p>
                                    <v-icon size="48px">$vuetify.icons.CheckVoucherSvg</v-icon>
                                </p>
                                <p class="text--h6 text-center">Check an existing voucher status & balance</p>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
        </v-dialog>
        <!-- End add item dialog -->
    </v-app>
</template>

<script>
import CommonApi from '@/services/CommonApi'
import moment from 'moment'
export default {
    name: "ApplyPayment",

    data: () => ({
        invoice_details : [],
        invoice_sub_total : '110',
        invoice_total : '110',
        balance : '0',

        pay_amount : 0,
        payment_modes : [],
        voucher_dialog : false,
        payment_detail_dialog : false,
        more_options: [
            // { label: 'Save Part-Paid', value: 1 },
            { label: 'Save Unpaid', value: 1 },
            { label: 'Payment Details', value: 2 }
        ],
        payment_drawer : false,
        pay_types : [],
        invoice_date : '',
        invoice_id : '',
        payments : [],
        customer : '',
        paid_status: '',
        tips : '',
        appointment_id : '',
        staffs : [],
        invoice_staff : '',
        invoice_notes : '',
        moment : moment
    }),
    mounted() {
        this.getPaymentModes();
        this.getInvoiceDetails();
        this.getDropdownsList();
    },
    methods : {
        more_option_click(val) {
            if(val == 1) {
                this.$router.push('/invoice/'+this.invoice_id);
            } else if(val == 2) {
                this.payment_detail_dialog = true;
            }
        },
        async getDropdownsList() {
            let branch_param = { branch: localStorage.getItem("branch") };
            branch_param = new URLSearchParams(branch_param);
            await CommonApi.get_data('innosoft_salon.api.get_all_data', branch_param)
            .then(res => {
                if (res.status_code == 200) {
                    this.staffs = res.data.staff_list;

                    if(this.invoice_staff == '') {
                        this.invoice_staff = res.data.staff_list[0].employee_name;
                    }
                }
            })
        },
        payment_type(type) {
            let found = this.pay_types.findIndex((el => el.type == type));
            if(found == -1) {
                let pay = { type: type, amount : this.pay_amount };
                this.pay_types.push(pay);
            } else {
                this.pay_types[found].amount = parseInt(this.pay_types[found].amount) + parseInt(this.pay_amount);
            }

            let total_pay_amount = 0;
            this.pay_types.forEach(val => {
                total_pay_amount += parseFloat(val.amount);
            }) 

            // this.payment_drawer = !this.payment_drawer;
            let amount_to_pay = this.invoice_total;

            if(total_pay_amount >= amount_to_pay) {
                this.balance = 0;
                this.payment_drawer = !this.payment_drawer;                
            } else {               
                this.balance = amount_to_pay - total_pay_amount;
                this.pay_amount = this.balance;
            } 
        },
        delete_pay(index) {
            this.pay_types.splice(index, 1);

            let total_pay_amount = 0;
            this.pay_types.forEach(val => {
                total_pay_amount += parseFloat(val.amount);
            })

            let amount_to_pay = this.invoice_total;

            if(total_pay_amount >= amount_to_pay) {
                this.balance = 0;
                this.payment_drawer = !this.payment_drawer;                
            } else {               
                this.balance = amount_to_pay - total_pay_amount;
                this.pay_amount = this.balance;
            } 
        },
        back_to_payments() {
            this.payment_drawer = !this.payment_drawer;

            this.pay_types = [];

            this.balance = this.invoice_total;
            this.pay_amount = this.invoice_total;
        },
        CompleteSale() {
            let mode_pay = [];
            this.pay_types.forEach(val => {
                let pay_data = {
                    mode_of_payment : val.type,
                    amount : val.amount,
                    voucher : 0,
                    voucher_no : '',
                }

                mode_pay.push(pay_data);
            })

            var post_data = new FormData();
            post_data.append("invoice_no", this.invoice_id)
            post_data.append("appointment", this.appointment_id)
            post_data.append("mode_of_payment", JSON.stringify(mode_pay))
            post_data.append("payment_received_by", this.invoice_staff)
            CommonApi.post_data('innosoft_salon.api.pay_for_unpaid', post_data)
            .then(res => {
                if(res) {
                    this.$router.push('/invoice/'+res.data.invoice);
                }
            });
        },
        async getPaymentModes() {
            await CommonApi.get_data('innosoft_salon.api.get_mode_of_payment')
            .then(res => {
                if (res.status_code == 200) {
                    this.payment_modes = res.data;
                }
            })
        },
        async getInvoiceDetails() {
            this.invoice_id = this.$route.params.invoice_id;

            let param = { invoice: this.invoice_id };
            let params = new URLSearchParams(param);
            await CommonApi.get_data('innosoft_salon.api.get_invoice_details', params)
            .then(res => {
                this.invoice_date = res.message.date;
                this.invoice_details = res.message.items;
                this.invoice_sub_total = res.message.net_total;
                this.invoice_total = res.message.grand_total;
                this.balance = res.message.balance_amount;
                this.payments = res.message.payments;
                this.customer = res.message.customer_name;
                this.paid_status = res.message.status;
                this.tips = res.message.tips;

                this.pay_amount = this.invoice_total;
                this.appointment_id = res.message.appointment;
                this.invoice_notes = res.message.invoice_note;

                if(res.message.payment_received_by != '') {
                    this.invoice_staff = res.message.payment_received_by;
                }
            })

        }
    }
}
</script>